import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  video: PropTypes.string
}

const defaultProps = {
  show: false,
  closeHidden: false,
  video: '',
  videoTag: 'iframe'
}

const Modal = ({
  className,
  handleClose,
  show,
  closeHidden,
  ...props
}) => {

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };    
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]); 
  
  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  }

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  }

  const stopProgagation = (e) => {
    e.stopPropagation();
  }

  const classes = classNames(
    'modal',
    show && 'is-active',
    className
  );

  return (
    <>
      {show &&
        <div
          {...props}
          className={classes}
          onClick={handleClose}
        >
          <div className="modal-inner" onClick={stopProgagation}>
            {!closeHidden &&
            <button
                className="modal-close"
                aria-label="close"
                onClick={handleClose}
            ></button>
            }
            <div className="modal-content privacy-modal">
              <h3 className="mt-0 mb-6">
                Privacy Policy
              </h3>
              <p className="m-0">
                Our privacy policy was posted on 9.3.2022.
              </p>
              <p className="m-0">
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights.
              </p>
              <h3 className="mt-24 mb-6">
                What do we collect?
              </h3>
              <p className="m-0">
                We collect, for technical reasons, some information when You interact with our site; Your IP address, browser User-Agent and time of each HTTP request.
                We do not, at the moment, use any 3rd party analytical tool to collect or store any other user information for the visitors of this site.
                Should these facts change in the future, we shall update our Privacy Policy statement accordingly to let You know about Your privacy rights.
              </p>
              <p className="m-0">
                In case you reach out to us via the provided communication channels (email, cellphone, social media), we might store Your contact information in our internal data records in order to track Your engagement as a Customer or Partner with our company.
              </p>
              <h3 className="mt-24 mb-6">
                How do we store and secure Your data?
              </h3>
              <p className="m-0">
                As previously stated, in case You establish contact with our company over the provided communication channels (email, cellphone, social media), some of Your personal data might be stored in our internal physical or electronic records.
                Other data which is collected at the server level (IP address, browser User-Agent and request time) is stored internally on the server of our hosting provider.
                This web site is hosted by the provider InfoNET DHH d.o.o. located in Pula, Croatia, and the physical location of the servers is in Zagreb, Croatia.
                All of the data transfer between this web site and Your browser is encrypted and delivered over HTTPS.
                We make sure that the personal data which we have collected is kept secure and is accessible only by authorized personnel.
                We implement appropriate security measures so that the personal data is stored in a secure way and is not misused.
                We shall not distribute, sell, make public or in any other way expose Your personal data.
              </p>
              <h3 className="mt-24 mb-6">
                Why do we collect and how we use it?
              </h3>
              <p className="m-0">
                The automated data collection which happens when You visit the site enables us to make sure that there is no harmful interaction with our site.
                For example in case of DDoS or similar attacks on the site, we may restrict access from a particular computer.
                This is why it is important that we process the IP address in the access logs of our hosting providers server logs.
                We use this data for the sole purpose to make sure the operation of our site is stable.
              </p>
              <p className="m-0">
                The other data that we might collect in case You initiate communication with us (email, cell phone) is collected in order to track Your engagement as a Customer or Partner with our company.
                We use this data in good faith in order to establish contact and cooperate with Your company.
                We do not distribute, sell or make this data public in any way.
              </p>
              <h3 className="mt-24 mb-6">
                How long do we keep Your personal data?
              </h3>
              <p className="m-0">
                The access log data that is collected automatically is kept on our hosting providers server for 1 year after which it is deleted.
              </p>
              <p className="m-0">
                Manually collected data which we store in case You establish contact with our Company will be stored for an indefinite period of time, unless You request for the data to be deleted.
              </p>
              <h3 className="mt-24 mb-6">
                Data breach
              </h3>
              <p className="m-0">
                In case of an illegal data breach from a malicious party of our hosting provider servers or our internal company electronic or physical records, we shall notify the appropriate government authorities no longer than 72 hours from the time of the breach.
              </p>
              <h3 className="mt-24 mb-6">
                Data processor information
              </h3>
              <p className="m-0">
                Legal entity that is acting as the data processor of personal information of this web site is Quantum Solutions d.o.o., and the data processing manager is Hrvoje Mesec.
                Our registered and operative office is located at:
              </p>
              <p className="m-0">
                Ulica don Frane Bulića 2, Popovec
              </p>
              <p className="m-0">
                10360, Sesvete
              </p>
              <p className="m-0">
                Zagreb, Croatia
              </p>
              <p className="m-0">
                <a href="mailto:info@quantum-solutions.hr?subject=Data">info@quantum-solutions.hr</a>
              </p>
              <h3 className="mt-24 mb-6">
                Your rights
              </h3>
              <p className="m-0">
                You have the right to request that we delete any personal data which we have collected manually in case You established contact with our company.
                In case you want to exercise Your, please reach out to us via the above provided contact email and describe Your request.
              </p>
              <p className="m-0">
                Regarding the data that is being automatically collected at the server level (IP address, browser User-Agent, request time), we do not delete it manually but leave it up to server settings as it will get deleted automatically after some time.
              </p>
              <h3 className="mt-24 mb-6">
                Changes to our privacy protection policy
              </h3>
              <p className="m-0">
                These rules on our privacy protection policy might change from time to time in order to keep up with legal regulations or industry standards.
                We shall not explicitly notify all of our clients or web site users in case of changes, but instead we advise that You regularly check this part of our web site in case the rules have changed.
              </p>
            </div>
          </div>
        </div>
      }
    </>
  )
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;