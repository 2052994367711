import React, { useState } from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import PrivacyModal from "../../elements/PrivacyModal";

const FooterNav = ({
  className,
  ...props
}) => {

  const [privacyModalActive, setPrivacyModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setPrivacyModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setPrivacyModalActive(false);
  }

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <HashLink smooth to="#services">Services</HashLink>
        </li>
        <li>
          <HashLink smooth to="#products">Products</HashLink>
        </li>
        <li>
          <HashLink smooth to="#contact">Contact</HashLink>
        </li>
        <li>
          <HashLink smooth to="#about">About Us</HashLink>
        </li>
        <li>
          <a href="#0" aria-controls="video-modal" onClick={openModal}>Privacy Protection</a>
        </li>
        <PrivacyModal
          id="privacy-modal"
          show={privacyModalActive}
          handleClose={closeModal} />
      </ul>
    </nav>
  );
}

export default FooterNav;