import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types
}

const defaultProps = {
  children: null,
  ...SectionProps.defaults
}

const GenericSection = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'About us',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} id="about" className="center-content" />
          <div className="about-us mb-24">
            <div className="about-us-left">
              <p className="m-0 mb-8">Quantum Solutions d.o.o.</p>
              <p className="m-0 text-xxs">Ulica don Frane Bulića 2, Popovec</p>
              <p className="m-0 text-xxs">10360 Sesvete</p>
              <p className="m-0 text-xxs">Zagreb</p>
              <p className="m-0 text-xxs">Croatia</p>
            </div>
            <div className="about-us-middle">
              <p className="m-0 mb-8 text-xxs">info@quantum-solutions.hr</p>
              <p className="m-0 mb-8 text-xxs">+385 98 876 563</p>
              <p className="m-0 mb-8 text-xxs"><a href="https://www.linkedin.com/company/quantum-solutions-d-o-o">LinkedIn</a></p>
            </div>
            <div className="about-us-right">
              <p className="m-0 mb-8 text-xxs">OIB / VAT number: 32713130038</p>
              <p className="m-0 mb-8 text-xxs">IBAN: HR0823600001102941345, account at Zagrebačka Banka d.d.</p>
              <p className="m-0 mb-8 text-xxs">MB: 5459141</p>
              <p className="m-0 mb-8 text-xxs">MBS: 081388171</p>
            </div>
          </div>
          <p className="m-0 text-xxs">The company is registered in the court register at the Commercial Court in Zagreb.</p>
          <p className="m-0 text-xxs">Share capital HRK 20,000.00 paid in full.</p>
          <p className="m-0 text-xxs">Member of the Management Board: Hrvoje Mesec, Director.</p>
        </div>
      </div>
    </section>
  );
}

GenericSection.propTypes = propTypes;
GenericSection.defaultProps = defaultProps;

export default GenericSection;