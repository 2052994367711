import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';


const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h3 className="m-0">
        <Link to="/">
        <Image
                      src={require('./../../../assets/images/logo-quantum-no-top.svg')}
                      alt="Quantum solutions"
                      width={280} />
        </Link>
      </h3>
    </div>
  );
}

export default Logo;